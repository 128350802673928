<template>
  <!-- Game Type4 (分類版塊) -->
  <div
    :id="'game-' + data.block"
    class="game-type4 my-2"
  >
    <swiper
      ref="gameItem"
      :options="swiperOption"
      class="mySwiper px-4 py-0"
    >
      <swiper-slide
        v-for="(item) in data.data.navbar"
        :key="item._id"
        style="width:auto"
      >
        <div
          class="game__item--card cursor-pointer rounded"
          :class="[`${item.class}-bg`]"
          @click="goVendorPage(item._id)"
        >
          <div
            class="game-card h-56px d-flex justify-center flex-column align-center"
          >
            <img
              v-if="navIcon(item)"
              :src="baseImgUrl+navIcon(item)"
              height="26"
            >
            <Icon
              v-else
              :data="icons[item.class]"
              width="20"
              height="26"
              class="bg--text"
            />
            <span class="caption bg--text">{{ item.game_type }}</span>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import esport from '@/assets/icons/block/game/type2/esport.svg'
import fish from '@/assets/icons/block/game/type2/fish.svg'
import live from '@/assets/icons/block/game/type2/live.svg'
import lottery from '@/assets/icons/block/game/type2/lottery.svg'
import slot from '@/assets/icons/block/game/type2/slot.svg'
import sport from '@/assets/icons/block/game/type2/sport.svg'
import card from '@/assets/icons/block/game/type2/card.svg'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    // swiper
    swiperOption: {
      slidesPerView: 'auto',
      spaceBetween: 8,
      centeredSlidesBounds: true,
    },

    icons: {
      esport,
      fish,
      live,
      lottery,
      slot,
      sport,
      card,
    },
    game: null,
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'theme']),
  },

  methods: {
    /**
     * 判斷現在是亮版或暗版 決定icon
     * @date 2023-01-08
     */
    navIcon(item) {
      return this.theme === 'dark' ? item.nav_icon_dark : item.nav_icon_light
    },
    /**
     * 到 vendor 頁
     * @date 2023-01-08
     * @param {string} vendorID
     */
    goVendorPage(vendorID) {
      this.$router.push({
        name: 'vendor',
        params: {
          id: vendorID,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.h-56px {
  height: 56px;
  min-width:40px;
  padding: 0 8px;
}

.card-bg{
  background: #dea554;
}
.fish-bg{
  background: #1cbaff;
}
.live-bg{
  background: #fd683c;
}
.lottery-bg{
  background: #febc08;
}
.slot-bg{
  background: #f08108;
}
.esport-bg{
  background: #27dce6;
}
.sport-bg{
  background: #ae4bff;
}
</style>
